<template>
  <CommonLayout>
    <div class="cabinet">
      <div class="cabinet__inner">
        <div class="cabinet__head">
          <div class="title">Рейтинг</div>
        </div>
        <div class="cabinet__body">
          <div class="cabinet__content">
            <div class="switcher" :class="{ active: type === 'user' }">
              <button
                @click="
                  $router.push({ name: 'rating', params: { type: 'team' } })
                "
                :disabled="type === 'team'"
              >
                Команды
              </button>
              <button
                @click="
                  $router.push({ name: 'rating', params: { type: 'user' } })
                "
                :disabled="type === 'user'"
              >
                Участники
              </button>
            </div>
            <div v-if="type === 'team'">
              <div class="search">
                <input
                  type="text"
                  v-model="searchTeam"
                  placeholder="Поиск по Команде"
                />
              </div>
              <div class="cabinet-table">
                <Loading v-if="loadingTeams" position="center" />
                <table>
                  <thead>
                    <tr>
                      <th>Имя</th>
                      <th width="30%">На текущей планете {{ COINS }}</th>
                      <th width="20%">Всего {{ COINS }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <router-link
                      v-for="(item, index) in filteredListTeam"
                      :key="index"
                      tag="tr"
                      :class="{ myself: item.teamName === getProfile.teamName }"
                      :to="{ name: 'team', params: { teamID: item.id } }"
                    >
                      <td>{{ item.teamName }}</td>
                      <td>
                        {{ item.calculatedCoinsCurrentIsland }}
                      </td>
                      <td>
                        {{ item.calculatedCoins }}
                      </td>
                    </router-link>
                  </tbody>
                </table>
                <div class="cabinet-table__more">
                  <button
                    v-if="
                      !searchTeam && limitTeam && limitTeam < getTeams.length
                    "
                    @click="limitTeam = null"
                  >
                    Смотреть все
                  </button>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="search">
                <input
                  type="text"
                  v-model="searchUser"
                  placeholder="Поиск по Участникам"
                />
              </div>
              <div class="cabinet-table">
                <Loading v-if="loadingUsers" position="center" />
                <table>
                  <thead>
                    <tr>
                      <th>Имя</th>
                      <th width="30%">На текущей планете {{ COINS }}</th>
                      <th width="20%">Всего {{ COINS }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <router-link
                      :class="{ myself: item.id === getProfile.id }"
                      :to="linkToProfile(item.id)"
                      tag="tr"
                      v-for="(item, index) in filteredListUser"
                      :key="index"
                    >
                      <td>{{ item.name }}</td>
                      <td>{{ item.calculatedCoinsCurrentIsland }}</td>
                      <td>{{ item.coins }}</td>
                    </router-link>
                  </tbody>
                </table>

                <div class="cabinet-table__more">
                  <button
                    v-if="
                      !searchUser &&
                      limitUser &&
                      limitUser < getUsersCoins.length
                    "
                    @click="limitUser = null"
                  >
                    Смотреть все
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="cabinet__sidebar">
            <Dashboard view="rating" />
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<script>
import CommonLayout from "@/layout/CommonLayout.vue";
import Dashboard from "@/components/Dashboard.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["type"],
  components: {
    CommonLayout,
    Dashboard,
  },
  data() {
    return {
      limitTeam: 7,
      limitUser: 7,
      limitSearch: 7,
      searchTeam: "",
      searchUser: "",
    };
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "getTeamsStatus",
      "getTeams",
      "getUsersCoinsStatus",
      "getUsersCoins",
    ]),
    loadingTeams() {
      return this.getTeamsStatus === "loading";
    },
    loadingUsers() {
      return this.getUsersCoinsStatus === "loading";
    },
    filteredListTeam() {
      if (this.searchTeam) {
        return this.getTeams
          .filter((item) => {
            return item.teamName
              .toLowerCase()
              .includes(this.searchTeam.toLowerCase());
          })
          .slice(0, this.limitSearch);
      }

      return this.limitTeam
        ? this.getTeams.slice(0, this.limitTeam)
        : this.getTeams;
    },
    filteredListUser() {
      if (this.searchUser) {
        return this.getUsersCoins
          .filter((item) => {
            return item.name
              .toLowerCase()
              .includes(this.searchUser.toLowerCase());
          })
          .slice(0, this.limitSearch);
      }

      return this.limitUser
        ? this.getUsersCoins.slice(0, this.limitUser)
        : this.getUsersCoins;
    },
  },
  watch: {
    type() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["TEAMS_FETCH", "USERS_COINS_FETCH"]),
    fetchData() {
      if (this.type === "team" && this.getTeamsStatus !== "success") {
        this.TEAMS_FETCH();
      }
      if (this.type === "user" && this.getUsersCoinsStatus !== "success") {
        this.USERS_COINS_FETCH();
      }
    },
    linkToProfile(userID) {
      // if (userID === this.getProfile.id) {
      //   return { name: 'profile' };
      // }
      return { name: "user", params: { userID: userID } };
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  margin-top: 30px;
  position: relative;
  input {
    width: 100%;
    height: 36px;
    background: #024a4b;
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.3143 0a6.3145 6.3145 0 016.3143 6.3143c0 1.564-.5732 3.0017-1.5155 4.1091l.2623.2623h.7675L17 15.5429 15.5429 17l-4.8572-4.8571v-.7675l-.2623-.2623c-1.1074.9423-2.5451 1.5155-4.1091 1.5155A6.3145 6.3145 0 010 6.3143 6.3143 6.3143 0 016.3143 0zm0 1.9429c-2.4286 0-4.3714 1.9428-4.3714 4.3714 0 2.4286 1.9428 4.3714 4.3714 4.3714 2.4286 0 4.3714-1.9428 4.3714-4.3714 0-2.4286-1.9428-4.3714-4.3714-4.3714z' fill='%234FFFEA'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 12px 50%;
    border: 1px solid #00ffff;
    box-sizing: border-box;

    font-family: $fontRafale;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: rgba(94, 255, 238, 1);
    outline: none;
    padding-left: 38px;
    &::placeholder {
      color: #2fbdae;
    }
  }
}
</style>
